import Link from 'next/link'
import { FunctionComponent, useContext, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import DeleteXCircle from 'components/SVG/DeleteXCircle'
import AlertCircle from 'components/SVG/compound/AlertCircle'

import { prepopulateIntercomMessage } from 'services/intercom'

interface IRelativeContainerProps {
  width?: string | number
}
const RelativeContainer = styled.div<IRelativeContainerProps>`
  position: relative;
  z-index: 0;
  ${props => props.width && `width: ${props.width};`}
`

const BackingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  z-index: -1;
`

interface ICenteredProps {
  centered?: boolean
}
interface IFlatBottomProps {
  flatBottom?: boolean
}
interface IHeightProps {
  height?: number
}
interface INoRadiusProps {
  noRadius?: boolean
}
interface IPaddingProps {
  extraPadding?: boolean
}
interface IDeleteProps {
  showDelete?: boolean
}
const Container = styled.div<
  ICenteredProps & IFlatBottomProps & IHeightProps & INoRadiusProps & IPaddingProps & IDeleteProps
>`
  padding: ${props => (props.extraPadding ? '20px' : '10px 12px')};
  background: ${props => props.theme.colors.neutral50};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  ${props => props.showDelete && 'justify-content: space-between;'}
  width: 100%;
  z-index: 200;

  ${props =>
    props.flatBottom
    && `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
  ${props => props.noRadius && 'border-radius: 0;'}
  ${props => props.height && `height: ${props.height}px`}
`

const IconContainer = styled.div`
  margin-right: 10px;
  height: 24px;
  width: 24px;
`

const ErrorText = styled.div<ICenteredProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  ${props => props.centered && 'text-align: center;'}

  color: ${props => props.theme.colors.neutral700};
  z-index: 200;
`

const InlineLink = styled.a`
  color: ${props => props.theme.colors.blue900};
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  text-transform: uppercase;
  padding-top: 10px;
  &:hover {
    color: ${props => props.theme.colors.blue7};
  }

  cursor: pointer;
`

const DeleteButton = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  cursor: pointer;
  background-color: none;
  border-width: 0px;
  margin-right: 5px;
`

interface IProps {
  banner?: boolean
  centered?: boolean
  chatMessage?: string
  error?: string | undefined
  flatBottom?: boolean
  height?: number
  iconType?: 'circle' | 'triangle' | 'card'
  linkText?: string
  linkUri?: string
  noRadius?: boolean
  prepopulatedIntercomMessage?: string
  showChatMessage?: boolean
  showLinkMessage?: boolean
  showDelete?: boolean
  width?: number | string
}

const ErrorBoxHomeRise: FunctionComponent<IProps> = ({
  banner,
  centered,
  chatMessage = 'Chat with us if you need help.',
  error,
  flatBottom,
  height,
  iconType,
  linkText,
  linkUri,
  noRadius,
  prepopulatedIntercomMessage = "I can't access a listing...",
  showChatMessage,
  showLinkMessage,
  showDelete,
  width,
}) => {
  // it's an easy mistake to pass an error object instead of an error object's string `message`
  // to this component, which will crash the app
  if (!error || typeof error !== 'string') return null

  const theme = useContext(ThemeContext)

  const [deleteErrorBox, setDeleteErrorBox] = useState(false)

  // triangle and credit card alert icons haven't been added to project, so just show circle
  const AlertIcon: React.ElementType | undefined = iconType ? AlertCircle : undefined

  const openIntercom = () => {
    prepopulateIntercomMessage(prepopulatedIntercomMessage)
  }

  const errorLines = useMemo(
    () => error.split('\n').map((text, idx) => ({ lineNumber: idx, text })),
    [error],
  )

  if (deleteErrorBox) return null

  return (
    <RelativeContainer width={width}>
      <Container
        centered={centered}
        extraPadding={banner}
        flatBottom={flatBottom}
        height={height}
        noRadius={noRadius}
        showDelete={showDelete}
      >
        {AlertIcon && (
          <IconContainer>
            <AlertIcon
              height={24}
              width={24}
              fill={theme.colors.red}
            />
          </IconContainer>
        )}
        <ErrorText centered={centered}>
          {/* NB: newline characters will be respected in your error string */}
          {errorLines.map(errorLine => (
            <div key={errorLine.lineNumber}>
              {errorLine.text}
              {errorLine.lineNumber === errorLines.length - 1 && showChatMessage && (
                <>
                  {' '}
                  <InlineLink onClick={openIntercom}>{chatMessage}</InlineLink>
                </>
              )}
            </div>
          ))}
          {showLinkMessage && linkText && linkUri && (
            <>
              {' '}
              <Link href={linkUri}>
                <InlineLink>{linkText}</InlineLink>
              </Link>
            </>
          )}
        </ErrorText>
        {showDelete && (
          <DeleteButton onClick={() => setDeleteErrorBox(true)}>
            <DeleteXCircle
              fill={theme.colors.red}
              width="20"
              height="20"
            />
          </DeleteButton>
        )}
      </Container>
      <BackingContainer />
    </RelativeContainer>
  )
}

export default ErrorBoxHomeRise
